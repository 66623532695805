'use strict'

let self;

export default self = {
  container : document.querySelector('.thumbnail-gallery'),
  overlay : document.querySelector('.thumbnail-gallery-overlay'),
  images : document.querySelectorAll('.thumbnail-gallery .col-3'),
  closeOverlay : document.querySelector('.thumbnail-gallery-overlay .btn-close'),
  overlayImage : document.querySelector('.thumbnail-gallery-overlay img'),
  overlayCaption : document.querySelector('.thumbnail-gallery-overlay .caption'),

  init  : () => {
    Array.from(self.images).forEach( image => image.addEventListener('click', self.toggleOverlay))
    self.closeOverlay.addEventListener('click', self.toggleOverlay)
  },

  toggleOverlay : e => {
    if (self.overlay.classList.contains('show')) {
      self.overlay.classList.remove('show')
      self.overlayImage.src = ''
      self.overlayCaption.innerText = ''
    } else {
      let bgImage = e.currentTarget.querySelector('.bg-image')
      let caption = bgImage.getAttribute('data-caption')
      let imageStyle = bgImage.style['background-image']
      let imagePath = imageStyle.match(/\((.*?)\)/)[1].replace(/('|")/g,'')

      self.overlay.classList.add('show')
      self.overlayImage.src = imagePath
      self.overlayCaption.innerText = caption
    }
  }
}
