
const self = {
  container : document.querySelector('.page__home-new'),

  init : () => {
    self.setupElements()
    self.setupEvents()
  },

  setupElements : () => {
    self.btnLearnMore = self.container.querySelector('.btn__learn-more')
    self.containerBelowFold = self.container.querySelector('.below-fold')
  },

  setupEvents : () => {
    self.btnLearnMore.addEventListener('click', self.toggleContent)
  },

  toggleContent : () => {
    const isHidden = self.containerBelowFold.classList.contains('hidden')

    self.containerBelowFold.classList.remove('hidden')

    window.scrollTo(0, window.pageYOffset + (window.innerHeight / 2))
  }

}

export default self
