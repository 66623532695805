
const self = {
  container : document.querySelector('.page__commitment'),

  init : () => {
    self.setupElements()
    self.setupEvents()
    self.onScroll()
  },

  setupElements : () => {
    self.leftColumn = self.container.querySelector('.page__commitment__left-column')
    self.openOverlayBtns = [...self.container.querySelectorAll('.image-cta button, .project-grid__item button, .caption, .project-link')]
    self.overlay = self.container.querySelector('.page__commitment__overlay')
    self.btnCloseOverlay = self.overlay.querySelector('.btn__close')
  },

  setupEvents : () => {
    self.openOverlayBtns.forEach( button => button.addEventListener('click', self.toggleOverlay) )
    self.btnCloseOverlay.addEventListener('click', self.toggleOverlay)
    window.addEventListener('scroll', self.onScroll)
  },

  toggleContent : () => {
    const isHidden = self.containerBelowFold.classList.contains('hidden')

    self.containerBelowFold.classList.remove('hidden')

    window.scrollTo(0, window.pageYOffset + (window.innerHeight / 2))
  },

  toggleOverlay : ({ currentTarget : button }) => {
    const rel = button.getAttribute('rel')

    self.overlay.classList.toggle('active')
    document.documentElement.classList.toggle('no-scroll')

    if ( rel ) {
      self.overlay.querySelector(`.project[rel="${rel}"]`).classList.add('show')
    } else {
      self.overlay.querySelector(`.project.show`).classList.remove('show')
    }
  },

  onScroll : () => {
    const isFixed = self.leftColumn.classList.contains('fixed')
    const scrolledPastTop = self.leftColumn.getBoundingClientRect().top < 67
    const scrolledPastBottom = self.leftColumn.getBoundingClientRect().bottom - window.innerHeight < 0


    if ( scrolledPastTop && !scrolledPastBottom && !isFixed ) self.leftColumn.classList.add('fixed')
    if ( !scrolledPastTop && !scrolledPastBottom && isFixed ) self.leftColumn.classList.remove('fixed')

    if ( scrolledPastBottom && isFixed ) {
      self.leftColumn.classList.remove('fixed')
      self.leftColumn.classList.add('absolute')
    }

    if ( !scrolledPastBottom && scrolledPastTop && !isFixed ) {
      self.leftColumn.classList.add('fixed')
      self.leftColumn.classList.remove('absolute')
    }
  }

}

export default self
