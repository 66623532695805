/*
Name: Reveal
Desc: Watches a set of elements and reveals them as they scroll into view. Add a class to all of the elements you want hidden, for example 'reveal-hidden' and apply some css to make the transition nice. Once the element comes into view, a 'reveal-show' class will be applied.

Usage:
import Reveal from './reveal';
Reveal.init({
  elements : '.reveal-hidden'
});

Example CSS
.reveal-show{
  transition: all .5s ease;
}
.reveal-hidden{
  opacity:0;
  transform: translate3d(0,100%,0);
}



*/
var _module;

export default _module = {
  inc: 0,
  nextel: null,
  coverHeight : (document.querySelector('#cover')) ? document.querySelector('#cover').clientHeight : 0,

  getPos : () => {
    return _module.nextel.getBoundingClientRect().top + (_module.coverHeight / 2);
  },

  init: function(args) {
    _module.elements = (typeof args.elements === 'string') ? document.querySelectorAll(args.elements) : args.elements;
    _module.nextel = _module.nextByOrder() || _module.elements[_module.inc];

    window.addEventListener('scroll', _module.onScroll);
    window.addEventListener('resize', _module.onResize);

    _module.onLoad();
  },

  onResize: function (e) {
    _module.coverHeight = (document.querySelector('#cover')) ? document.querySelector('#cover').clientHeight : 0;
  },

  onScroll: function (e) {
    if (!_module.nextel) {
      window.removeEventListener('scroll', _module.onScroll);
      return
    }

    let pos = _module.getPos();
    let eq = pos - window.innerHeight - _module.nextel.clientHeight;
    let inview = eq < 0;
    if (!inview) return;

    _module.showElement();
  },

  onLoad: function () {
    let pos = _module.getPos();
    let eq = pos - window.innerHeight - _module.nextel.clientHeight;
    let inview = eq < 0;

    if (inview) {
      _module.showElement();

      if (_module.nextel) {
        _module.onLoad();
      }
    }
  },

  showElement: function () {
    var showel = _module.nextel;

    setTimeout(() => {
      showel.classList.remove('reveal-hidden');
      showel.classList.add('reveal-show');
    }, 50);

    _module.inc += 1;
    _module.nextel = _module.nextByOrder() || _module.elements[_module.inc];
  },

  nextByOrder: function () {
    var o;

    for (var k = 0; k < _module.elements.length; k++) {
      o = parseInt(_module.elements[k].getAttribute('data-reveal-order'));

      if (o === _module.inc) {
        return _module.elements[k];
      }
    }
  }
};
