'use strict'

let self;

export default self = {
  container : document.querySelector('.lightbox-wrapper'),

  init  : () => {

    // For each timeline block...
    [...document.querySelectorAll('[data-popup]')].forEach(popup => {
      popup.addEventListener('click', (e) => {
        e.preventDefault();
        // document.querySelector(popup.getAttribute('href')).innerHTML
        
        document.querySelector('.lightbox-modal__content').innerHTML = document.querySelector(popup.getAttribute('href')).innerHTML;
        document.querySelector('.lightbox-modal').scrollTop = 0;
        document.querySelector('.lightbox-modal').classList.add('lightbox-modal--open');
        document.body.classList.add('lightbox-active');
      });
    })

    document.querySelector('.lightbox-modal__close').addEventListener('click', (e) => {
      e.preventDefault();
      document.querySelector('.lightbox-modal').classList.remove('lightbox-modal--open');
      document.body.classList.remove('lightbox-active');
    });

    document.body.addEventListener('mousedown', (e) => {
      if ( e.target.classList.contains('lightbox-modal--open') ) {
        document.querySelector('.lightbox-modal__close').click();
      }
    });
  }
}
