/*
 Name: Slider
 Desc: Creates a simple slideshow with basic options

 Usage:
  - The slideshow needs a bare minimum of the following Html
    <div class="slideshow">
      <div class="slides">
        <div class="slide show"></div>
      </div>
    </div>

  - You can alter any of the args below as you see fit.

    container : [String] - The container css selector, can be any valid css selector.

    pause : [Number] - The time to pause on each slide.

    pager : [Boolean] - If true, a set of elements will be created to represent a pager system, all elements navigate to the corresponding slide.

    count : [Boolean] - If true, a set of elements will be created that display the current slide and the total number of slides eg. 1 / 10.

  - For multiple slider instances, create an array of objects in your app.js arguments and specify the unique css selector for each slider
*/
let _module;

export default _module = {
  instances : [],

  init : function (args) {
    var ins;

    // If only one slider is passed, turn the object into an array
    if (!args) { args = [args]};
    args = (!args[0]) ? [args] : args;

    for (var i in args) {

      // Generate each instances unique options
      _module.instances[i] = {
        el : document.querySelector(args[i].container) || document.querySelector('.slideshow'),
        pause : args[i].pause || 5000,
        pager : args[i].pager || false,
        count : args[i].count || false,
        inc : 0,
        loop : null,
        pagerContainer : null,
        pagerElms : null,
        countContainer : null
      }
      _module.instances[i].slides = _module.instances[i].el.querySelectorAll('.slide');

      // Store the instance reference, this will be passed down the chain
      ins = _module.instances[i];

      // Setup pager
      if (ins.pager) {
        _module.setPaginationLinks(ins);
      }

      // Set Count
      if (ins.count) {
        _module.setSlideCount(ins);
      }

      // Start Looping
      ins.loop = setInterval(_module.next.bind(null, ins), ins.pause);
    }
  },

  setSlideCount : function (ins) {
    var cur, sep, total;

    // Build the container
    ins.countContainer = document.createElement('div');
    ins.countContainer.classList.add('count');

    // Add container to dom and store in module
    ins.el.appendChild(ins.countContainer);

    // Current slide number
    cur = document.createElement('span');
    cur.classList.add('cur');
    cur.innerHTML = '1';
    ins.countContainer.appendChild(cur);

    // Seperator
    sep = document.createElement('span');
    sep.classList.add('cur');
    sep.innerHTML = '/';
    ins.countContainer.appendChild(sep);

    // Total number of slides
    total = document.createElement('span');
    total.classList.add('total');
    total.innerHTML = ins.slides.length;
    ins.countContainer.appendChild(total);

  },

  setPaginationLinks : function (ins) {
    var span;

    // Build the container
    ins.pagerContainer = document.createElement('div');
    ins.pagerContainer.classList.add('pager');

    // Add container to dom and store in module
    ins.el.appendChild(ins.pagerContainer);

    // Create links
    for (var i = 0; i < ins.slides.length; i++) {
      span = document.createElement('span');

      if(i===0){
        span.classList.add('show');
      }

      // For referencing when you click
      span.setAttribute('data-inc', i);

      // Append to DOM
      ins.pagerContainer.appendChild(span);

      // Assign Event Listner
      // TODO: could be listener on container
      span.addEventListener('click', _module.goto.bind(null, ins));
    }

    // Store all pager elemnts in an array
    ins.pagerElms = ins.pagerContainer.querySelectorAll('span');
  },

  next : function (ins) {
    _module.removeActive(ins);

    // increment if next element exists, otherwise reset to 0
    ins.inc = ( ins.slides[ins.inc].nextElementSibling ) ? (ins.inc + 1) : 0;

    _module.setActive(ins);
  },

  removeActive : function (ins) {
    ins.slides[ins.inc].classList.remove('show');

    if (ins.pager) {
      ins.pagerElms[ins.inc].classList.remove('show');
    }
  },

  setActive : function (ins) {
    ins.slides[ins.inc].classList.add('show');

    if (ins.pager) {
      ins.pagerElms[ins.inc].classList.add('show');
    }

    if (ins.count) {
      ins.countContainer.querySelector('.cur').innerHTML = (ins.inc + 1);
    }
  },

  goto : function (ins, e) {

    // ignore click if the current slide is clicked
    if (e.currentTarget.classList.contains('show')){ return; }

    // Stop the loop
    clearInterval(ins.loop);

    _module.removeActive(ins);

    // Grab the new inc from the element
    ins.inc = parseInt(e.currentTarget.getAttribute('data-inc'));

    _module.setActive(ins);

    // Start a new loop
    ins.loop = setInterval(_module.next.bind(null, ins), ins.pause);

  }
};
