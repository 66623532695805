
const self = {
  container : document.querySelector('.page__home'),

  init : () => {
    self.setupElements()
    self.setupEvents()
  },

  setupElements : () => {
    self.slides = [...self.container.querySelectorAll('.slide')]
    self.pageButtons = [...self.container.querySelectorAll('.pages button')]
    self.btnShowMore = self.container.querySelector('.btn__show-more')
    self.moreCopyContainer = self.container.querySelector('.more-copy')
    //self.blockQuoteSlider = self.container.querySelector('.blockquote-slider')
  },

  setupEvents : () => {
    self.pageButtons.forEach( button => button.addEventListener('click', self.changeSlide) )
    self.btnShowMore.addEventListener('click', self.toggleContent)

    //setInterval(self.cycleBlockquotes, 5000)
  },

  changeSlide : ({ currentTarget : button }) => {
    const index = button.innerHTML

    self.updatePageButtonStates(button)
    self.updateSlidesStates(index)
  },

  updatePageButtonStates : activeButton => {
    self.pageButtons.forEach( button => button.classList.contains('active') && button.classList.remove('active') )
    activeButton.classList.add('active')
  },

  updateSlidesStates : index => {
    self.slides.forEach( slide => slide.classList.contains('active') && slide.classList.remove('active') )
    self.container.querySelector(`.slide[data-index="${index}"]`).classList.add('active')
  },

  toggleContent : () => {
    const isHidden = !self.container.classList.contains('show-copy')

    self.container.classList.toggle('show-copy')

    if ( isHidden ) {
      window.scrollTo(0, (window.pageYOffset + 200) )
    }
  },

  cycleBlockquotes : () => {
    const activeBlockquote = self.blockQuoteSlider.querySelector('blockquote.show')

    activeBlockquote.classList.remove('show')

    if ( activeBlockquote.nextElementSibling ) {
      activeBlockquote.nextElementSibling.classList.add('show')
    } else {
      self.blockQuoteSlider.children[0].classList.add('show')
    }
  }
}

export default self
