'use strict'

let self;

export default self = {
  container : document.querySelector('.accordion'),
  titles : document.querySelectorAll('.accordion__title'),

  init  : () => {
    Array.from(self.titles).forEach( title => title.addEventListener('click', self.toggleItem))
  },

  toggleItem : e => {
    let item = e.currentTarget.parentNode
    let content = item.querySelector('.accordion__content')
    let h = item.querySelector('.wrap').clientHeight

    if (item.classList.contains('open')) {
      item.classList.remove('open')
      content.removeAttribute('style')
    } else {
      item.classList.add('open')
      content.style.height = `${h}px`
    }
  }
}
