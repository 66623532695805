'use strict'

let self;

export default self = {
  container : document.querySelector('.timeline'),

  init  : () => {
      let i = 0;
      // Main timeline wrapper
      document.querySelector('.timeline').classList.add('timeline--ready');

      // For each timeline block...
      [...document.querySelectorAll('.timeline-block')].forEach(timeline => {
        i += 1;
        setTimeout(() => {
          timeline.classList.add('timeline-block--show');
        }, (i * 1000));
      })
  }
}
