'use strict';

import 'babel-polyfill'
import Reveal from './modules/reveal';
import Slider from './modules/slider';
import ThumbnailGallery from './modules/thumbnail-gallery'
import Accordion from './modules/accordion'
import Lightbox from './modules/lightbox'
import Timeline from './modules/timeline'
import Home from './modules/home'
import HomeNew from './modules/home-new'
import Commit from './modules/commit'

// Sliders
let slideshow = document.querySelectorAll('.slideshow');
if (slideshow[0]) {
  for (var i = 0; i < slideshow.length; i++) {
    let id = slideshow[i].getAttribute('id');

    Slider.init({
      container : '#' + id,
      pause : 6000
    })
  }
}


// FIXME: should be server side
let cover = document.getElementById('cover');
if (cover) document.documentElement.classList.add('has-cover');


// Mobile nav
let hamburger = document.getElementById('hamburger');
let drawer = document.getElementById('drawer');
hamburger.addEventListener('click', () => {
  if (hamburger.classList.contains('navopen')){
    hamburger.classList.remove('navopen');
    drawer.classList.remove('navopen');
  } else {
    hamburger.classList.add('navopen');
    drawer.classList.add('navopen');
  }
});


// Reveal
let revealElms = document.querySelector('.reveal-hidden');
if (revealElms) Reveal.init({elements : '.reveal-hidden' });


if (ThumbnailGallery.container) ThumbnailGallery.init()
if (Accordion.container) Accordion.init()
if (Lightbox.container) Lightbox.init()
if (Timeline.container) Timeline.init()
if (Home.container) Home.init()
if (HomeNew.container) HomeNew.init()
if (Commit.container) Commit.init()



const globalBanner = document.querySelector('.global__banner')
if ( globalBanner ) {
  setTimeout( () => globalBanner.classList.add('show'), 500)
  globalBanner.querySelector('button').addEventListener('click', () => globalBanner.classList.remove('show'))
}
